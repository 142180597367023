import * as THREE from 'three'
import { OrbitControls } from 'three/addons/controls/OrbitControls.js'
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js'
import GUI from 'lil-gui'
import CustomShaderMaterial from 'three-custom-shader-material/vanilla'
import wobbleVertexShader from './shaders/wobble/vertex.glsl'
import wobbleFragmentShader from './shaders/wobble/fragment.glsl'
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import gsap from 'gsap'


/**
 * Base
 */
// Debug
//const gui = new GUI({ width: 325 })
const debugObject = {}

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Loaders
const rgbeLoader = new RGBELoader()
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('./draco/')
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Environment map
 */
rgbeLoader.load('./urban_alley_01_1k.hdr', (environmentMap) =>
{
    environmentMap.mapping = THREE.EquirectangularReflectionMapping

    //scene.background = environmentMap
    scene.environment = environmentMap
})

/**
 * Wobble
 */
// Material

debugObject.colorA = '#4b0764'
debugObject.colorB = '#88288f'

const uniforms = {
    uTime: new THREE.Uniform(0),
    uPositionFrequency: new THREE.Uniform(0.784),
    uTimeFrequency: new THREE.Uniform(0.29),
    uStrength: new THREE.Uniform(1.547),
   // uColor: new THREE.Uniform(new THREE.Color('purple')),

    uColorA: new THREE.Uniform(new THREE.Color(debugObject.colorA)),
    uColorB: new THREE.Uniform(new THREE.Color(debugObject.colorB)),
    uWarpTime: new THREE.Uniform(0),
    uWarpPositionFrequency: new THREE.Uniform(0.119),
    uWarpTimeFrequency: new THREE.Uniform(0.),
    uWarpStrength: new THREE.Uniform(0),

}


const material = new CustomShaderMaterial({
    // CSM
    baseMaterial: THREE.MeshPhysicalMaterial,
    vertexShader: wobbleVertexShader,
    fragmentShader: wobbleFragmentShader,
    silent: true,
    uniforms: uniforms,
    // MeshPhysicalMaterial
    metalness: 1,
    roughness: 0.66,
    color: 'purple',
    transmission: 0,
    ior: 1.5,
    thickness: 1.5,
    transparent: true,
    wireframe: false
})

// Depth Material
const depthMaterial = new CustomShaderMaterial({
    // CSM
    baseMaterial: THREE.MeshDepthMaterial,
    vertexShader: wobbleVertexShader,
    silent: true,
    uniforms: {
        uColor: { value: new THREE.Color('purple') },
    },

    //MeshDepthMaterial
    depthPacking: THREE.RGBADepthPacking,
})


/*
// Tweaks
gui.add(material, 'metalness', 0, 1, 0.001)
gui.add(material, 'roughness', 0, 1, 0.001)
gui.add(material, 'transmission', 0, 1, 0.001)
gui.add(material, 'ior', 0, 10, 0.001)
gui.add(material, 'thickness', 0, 10, 0.001)
//gui.addColor(material, 'color')
//gui.addColor(material.uniforms.uColor, 'value').name('color')
gui.addColor(debugObject, 'colorA').onChange(() => uniforms.uColorA.value.set(debugObject.colorA))
gui.addColor(debugObject, 'colorB').onChange(() => uniforms.uColorB.value.set(debugObject.colorB))
gui.add(uniforms.uPositionFrequency, 'value', 0, 2, 0.001).name('uPositionFrequency')
gui.add(uniforms.uTimeFrequency, 'value', 0, 2, 0.001).name('uTimeFrequency')
gui.add(uniforms.uStrength, 'value', 0, 2, 0.001).name('uStrength')
gui.add(uniforms.uWarpPositionFrequency, 'value', 0, 2, 0.001).name('uWarpPositionFrequency')
gui.add(uniforms.uWarpTimeFrequency, 'value', 0, 2, 0.001).name('uWarpTimeFrequency')
gui.add(uniforms.uWarpStrength, 'value', 0, 2, 0.001).name('uWarpStrength')
*/

// Geometry
let geometry = new THREE.IcosahedronGeometry(2.5, 50)
geometry.scale(1, 1, 1)
geometry = mergeVertices(geometry)
geometry.computeTangents()
console.log(geometry.attributes)

// Mesh
const wobble = new THREE.Mesh(geometry, material)
wobble.customDepthMaterial = depthMaterial
wobble.receiveShadow = true
wobble.castShadow = true
scene.add(wobble)



/**
 * Plane

const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(15, 15, 15),
    new THREE.MeshStandardMaterial()
)
plane.receiveShadow = true
plane.rotation.y = Math.PI
plane.position.y = - 5
plane.position.z = 5
scene.add(plane)
 */

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 1)
directionalLight.castShadow = false
directionalLight.shadow.mapSize.set(1024, 1024)
//directionalLight.shadow.camera.far = 15
directionalLight.shadow.normalBias = 0.01
directionalLight.position.set(0.25, 2, - 1.25)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
    pixelRatio: Math.min(window.devicePixelRatio, 2)
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    sizes.pixelRatio = Math.min(window.devicePixelRatio, 2)

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(sizes.pixelRatio)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.set(13, - 3, - 5)
//camera.position.z = 6
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
// Disable zoom
controls.enableZoom = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
    
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1
renderer.setSize(sizes.width , sizes.height )
renderer.setPixelRatio(sizes.pixelRatio)


/** check viewport */
const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Materials
    uniforms.uTime.value = elapsedTime

    // Update controls
    controls.update()

    // Check if portfolio is in viewport
    const homeSection = document.querySelector('#home-section')
    const imageSection = document.querySelector('#image-section')
    const introSection = document.querySelector('#intro-section')
    const portfolioSection = document.querySelector('#portfolio-section')
    const skillsSection = document.querySelector('#skills-section')
    const skillsDisplaySection = document.querySelector('#skills')
    const contactSection = document.querySelector('#contact-section')
    const contactDetailsSection = document.querySelector('#contact-details-section')

    if (!isInViewport(contactDetailsSection)) {
        controls.enabled = true
       
    } else {
        controls.enabled = false
    }

    if (isInViewport(homeSection)) {
        // Colors when in viewport
        gsap.to(uniforms.uColorA.value, { r: 0.394, g: 0.027, b: 0.492, duration: 0.3 });
        gsap.to(uniforms.uColorB.value, { r: 0.533, g: 0.157, b: 0.661, duration: 0.3 });
        gsap.to(uniforms.uStrength, { value: 1.2, duration: 1 });
        gsap.to(uniforms.uPositionFrequency, { value: 0.584, duration: 1 });
    }
    else if (isInViewport(imageSection)) {
        // Colors when in viewport
        gsap.to(uniforms.uColorA.value, { r: 0.394, g: 0.027, b: 0.492, duration: 0.3 });
        gsap.to(uniforms.uColorB.value, { r: 0.533, g: 0.157, b: 0.661, duration: 0.3 });
        gsap.to(uniforms.uStrength, { value: 1.2, duration: 1 });
        gsap.to(uniforms.uPositionFrequency, { value: 0.584, duration: 1 });
    }
    else if (isInViewport(introSection)) {
        // Colors when in viewport
        gsap.to(uniforms.uColorA.value, { r: 0.394, g: 0.027, b: 0.192, duration: 0.3 });
        gsap.to(uniforms.uColorB.value, { r: 0.533, g: 0.157, b: 0.961, duration: 0.3 });
        gsap.to(uniforms.uStrength, { value: 1.2, duration: 1 });
        gsap.to(uniforms.uPositionFrequency, { value: 1, duration: 1 });
    } else if (isInViewport(portfolioSection)) {
        // Colors when in viewport
        gsap.to(uniforms.uColorA.value, { r: 1, g: 0.027, b: 0.192, duration: 0.3 });
        gsap.to(uniforms.uColorB.value, { r: 0.777, g: 0.057, b: 0.961, duration: 0.3 });
        gsap.to(uniforms.uStrength, { value: 0.5, duration: 1 });
        gsap.to(uniforms.uPositionFrequency, { value: 1, duration: 1 });
    } else if (isInViewport(skillsSection)) {
        // Colors when in skills section (make it red)
        gsap.to(uniforms.uColorA.value, { r: 1, g: 0, b: 0, duration: 0.5 }); // Red
        gsap.to(uniforms.uColorB.value, { r: 1, g: 0, b: 0, duration: 0.5 });
        gsap.to(uniforms.uStrength, { value: 1.2, duration: 0.5 });
        gsap.to(uniforms.uPositionFrequency, { value: 0.684, duration: 0.5 });
    } else if (isInViewport(skillsDisplaySection)) {
        // Colors when in skills section (make it red)
        gsap.to(uniforms.uColorA.value, { r: 1, g: 0, b: 0, duration: 0.3 }); // Red
        gsap.to(uniforms.uColorB.value, { r: 1, g: 0, b: 1, duration: 0.3 });
        gsap.to(uniforms.uStrength, { value: 1.2, duration: 0.3 });
        gsap.to(uniforms.uPositionFrequency, { value: 1, duration: 1 });
    }     else if (isInViewport(contactSection)) {
        // Colors when in viewport
        gsap.to(uniforms.uColorA.value, { r: 0, g: 0, b: 0, duration: 0.3 });
        gsap.to(uniforms.uColorB.value, { r: 0, g: 0.031, b: 1, duration: 0.3 });
        gsap.to(uniforms.uStrength, { value: 1, duration: 0.3 });
        gsap.to(uniforms.uPositionFrequency, { value: 0.784, duration: 0.3 });
    }  else if (isInViewport(contactDetailsSection)) {
        // Colors when in viewport
        gsap.to(uniforms.uColorA.value, { r: 0, g: 0, b: 0, duration: 0.3 });
        gsap.to(uniforms.uColorB.value, { r: 0, g: 0.071, b: 1, duration: 0.3 });
        gsap.to(uniforms.uStrength, { value: 1.2, duration: 0.3 });
        gsap.to(uniforms.uPositionFrequency, { value: 1, duration: 0.3 });
    } 
    

    //Rotate
    wobble.rotation.y += 0.002 
   // wobble.rotation.x += 0.001

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()

